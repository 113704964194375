import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        return 'Just now';
      }

      const intervals: { [key: string]: number } = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };

      const intervalsMap: { [key: string]: string } = {
        year: 'y',
        month: 'M',
        week: 'w',
        day: 'd',
        hour: 'h',
        minute: 'm',
        second: 's',
      };

      let counter;
      for (const time in intervals) {
        counter = Math.floor(seconds / intervals[time]);
        if (counter > 0)
          return (
            counter +
            (args === 'short' ? '' : ' ') +
            (args === 'short' ? intervalsMap[time] : time) +
            (args === 'short' || counter === 1 ? '' : 's') +
            ' ago'
          ); // singular (1 day ago)
      }
    }
    return value;
  }
}
