<ion-header class="ion-no-border">
  <div class="header">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="modalController.dismiss()">
          <ion-icon name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <h6 class="sub1">{{ type === "register" ? "Add" : "Edit" }} Client User</h6>
  </div>
</ion-header>

<ion-content>
  <form novalidate autocomplete="country" #clientUserForm="ngForm" *ngIf="user">
    <div class="row">
      <ion-item lines="none">
        <ion-label position="stacked" class="body2">
          Name <span class="danger">*</span></ion-label
        >
        <ion-input
          type="text"
          name="name"
          [(ngModel)]="user.name"
          ngDefaultControl
          #nameCtrl="ngModel"
          required
        ></ion-input>
      </ion-item>
    </div>

    <div class="row">
      <ion-item lines="none">
        <ion-label position="stacked" class="body2">
          Email <span class="danger">*</span></ion-label
        >
        <ion-input
          name="email"
          type="text"
          autocomplete="text"
          [(ngModel)]="user.email.value"
          ngDefaultControl
          #userEmailCtrl="ngModel"
          pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
          required
          disabled="{{ isDisabled }}"
        >
        </ion-input>
      </ion-item>
    </div>

    <div class="row">
      <ion-item lines="none" class="countryCode">
        <ion-label position="stacked" class="body2">
          Country code
          <span class="danger">*</span>
        </ion-label>
        <ion-input
          type="text"
          name="phone-prefix"
          [(ngModel)]="user.phone.countryCode"
          pattern="^\+[0-9]{2,3}"
          ngDefaultControl
          #userCountryCodeCtrl="ngModel"
          autocomplete="country"
          required
          disabled="{{ isDisabled }}"
          autocomplete="off"
          pattern="^\+[\d]{1,3}$"
        ></ion-input>
      </ion-item>

      <ion-item lines="none" class="phoneNumber">
        <ion-label position="stacked" class="body2">
          Phone Number <span class="danger">*</span></ion-label
        >
        <ion-input
          name="phone"
          type="text"
          autocomplete="text"
          [(ngModel)]="user.phone.number"
          ngDefaultControl
          #userEmailCtrl="ngModel"
          pattern="^[\d]{10}$"
          required
          disabled="{{ isDisabled }}"
        >
        </ion-input>
      </ion-item>
    </div>

    <div class="row">
      <ion-item lines="none">
        <ion-label position="stacked" class="body2">Bio</ion-label>
        <ion-textarea
          type="text"
          name="bio"
          [(ngModel)]="user.bio"
          ngDefaultControl
          #bioCtrl="ngModel"
          rows="7"
        ></ion-textarea>
      </ion-item>
    </div>

    <div class="row">
      <div class="input-container">
        <ion-label position="stacked"
          >Organization <span class="danger">*</span></ion-label
        >

        <ion-item class="type-wrap" lines="none" *ngIf="organizations.length">
          <ion-select
            class="custom-select"
            labelPlacement="floating"
            name="organization"
            id="organizationId"
            [(ngModel)]="user.organizationId"
            ngDefaultControl
            #typeCtrl="ngModel"
            [multiple]="false"
            required
            [disabled]="isDisabled"
          >
            <ion-select-option
              [value]="organization.organizationId"
              *ngFor="let organization of organizations"
            >
              {{ organization.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>
    </div>

    <div class="row">
      <div class="input-container">
        <ion-label position="stacked"
          >Role <span class="danger">*</span></ion-label
        >
        <ion-item class="type-wrap" lines="none">
          <ion-select
            class="custom-select"
            labelPlacement="floating"
            name="role"
            id="userId"
            [(ngModel)]="user.role"
            ngDefaultControl
            #typeCtrl="ngModel"
            [multiple]="false"
            required
          >
            <ion-select-option
              [value]="role.key"
              *ngFor="let role of roles | keyvalue"
            >
              {{ role.value }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>
    </div>

    <div class="row">
      <div class="input-container">
        <ion-label position="stacked">Gender</ion-label>
        <ion-item class="type-wrap" lines="none">
          <ion-select
            class="custom-select"
            labelPlacement="floating"
            name="gender"
            id="userId"
            [(ngModel)]="user.gender"
            ngDefaultControl
            #typeCtrl="ngModel"
            [multiple]="false"
          >
            <ion-select-option
              [value]="category.key"
              *ngFor="let category of gender | keyvalue"
              >{{ category.value }}</ion-select-option
            >
          </ion-select>
        </ion-item>
      </div>
    </div>

    <ion-footer class="ion-no-border">
      <ion-button fill="outline" (click)="save()">
        <ion-label>Done</ion-label>
      </ion-button>
    </ion-footer>
  </form>
</ion-content>
