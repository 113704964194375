import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettings } from '../../settings/app-settings';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() itemsPerPage: number = AppSettings.ITEMS_PER_PAGE;
  @Input() totalItems: number = 0;
  @Input() selectedPage: number = 1;
  @Input() align: 'left' | 'center' | 'right' = 'center';
  @Input() maxPages: number = 7;

  pages: number[] = [];

  lastPage: number = 0;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.generatePages();
  }

  ngOnChanges() {
    this.generatePages();
  }

  generatePages() {
    this.pages = [];
    const totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    let low =
      this.selectedPage - Math.floor(this.maxPages / 2) < 1
        ? 1
        : this.selectedPage - Math.floor(this.maxPages / 2);
    let high =
      this.selectedPage + Math.floor(this.maxPages / 2) > totalPages
        ? totalPages
        : this.selectedPage + Math.floor(this.maxPages / 2);

    for (let i = low; i <= high; i++) {
      this.pages.push(i);
    }

    this.lastPage = this.pages[this.pages.length - 1];
  }

  changePage(i: number) {
    this.selectedPage = i;
    this.generatePages();
    this.pageChanged.emit(this.selectedPage);
  }
}
