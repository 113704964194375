import { CreateAssetDTO } from '../../dtos/assets/create-asset-dto/create-asset.dto';
import { UpdateAssetDTO } from '../../dtos/assets/update-asset-dto/update-asset.dto';
import { AssetVersionStatus } from '../../settings/app-settings';
import { AssetVersion } from '../asset-versions/asset-version';
import { Logo } from '../logo/logo';
import { Organization } from '../organizations/organization';
import { Project } from '../projects/project';
import { SupportingFile } from '../supporting-file/supporting-file';
import { SupportingFiles } from '../supporting-files/supporting-files';

export class Asset {
  offsetPrinterId: string = '';

  offsetPrinterOrganization: Organization = new Organization();

  projectId: string = '';

  project: Project = new Project();

  clientId: string = '';

  clientOrganization: Organization = new Organization();

  creatorId: string = '';

  assetId: string = '';

  name: string = '';

  description?: string = '';

  assetVersions: AssetVersion[] = [];

  type: 'ARTWORK' = 'ARTWORK';

  status: AssetVersionStatus = 'UNASSIGNED';

  supportingFiles?: SupportingFiles = new SupportingFiles();

  deleted: boolean = false;

  timestamp?: { createdAt: number; updatedAt: number };

  constructor(asset?: { [key: string]: any }) {
    if (asset) {
      this.offsetPrinterId = asset['offsetPrinterId'] || '';

      if (
        typeof asset['offsetPrinterId'] === 'object' ||
        asset['offsetPrinterOrganization']
      ) {
        this.offsetPrinterOrganization = new Organization(
          asset['offsetPrinterId']
        );
        this.offsetPrinterId = this.offsetPrinterOrganization.organizationId;
      } else {
        this.offsetPrinterId = asset['offsetPrinterId'];
      }

      this.projectId = asset['projectId'] || '';

      if (typeof asset['projectId'] === 'object' || asset['project']) {
        this.project = new Project(asset['projectId']);
        this.projectId = this.project.projectId;
      } else {
        this.projectId = asset['projectId'];
      }

      this.clientId = asset['clientId'] || '';

      if (
        typeof asset['clientId'] === 'object' ||
        asset['clientOrganization']
      ) {
        this.clientOrganization = new Organization(asset['clientId']);
        this.clientId = this.clientOrganization.organizationId;
      } else {
        this.clientId = asset['clientId'];
      }

      this.creatorId = asset['creatorId'] || '';

      this.name = asset['name'] || '';

      this.assetId = asset['assetId'] || '';

      this.description = asset['description'] || '';

      this.type = asset['type'] || 'ARTWORK';

      this.status = asset['status'] || 'UNASSIGNED';

      if (asset['supportingFiles']) {
        this.supportingFiles = new SupportingFiles(asset['supportingFiles']);
      }

      if (asset['assetVersions']) {
        for (let assetVersion of asset['assetVersions']) {
          this.assetVersions.push(new AssetVersion(assetVersion));
        }
      }

      if (
        String(asset['deleted']) === 'true' ||
        String(asset['deleted']) === 'false'
      ) {
        this.deleted = String(asset['deleted']) === 'true';
      }

      this.timestamp = asset['timestamp'] || {
        createdAt: null,
        modifiedAt: null,
      };
    }
  }

  toCreateDTO(): CreateAssetDTO {
    return {
      offsetPrinterId: this.offsetPrinterId,
      projectId: this.projectId,
      clientId: this.clientId,
      creatorId: this.creatorId,
      name: this.name,
      description: this.description,
      type: this.type,
      deleted: this.deleted,
    };
  }

  toUpdateDTO(): UpdateAssetDTO {
    return {
      assetId: this.assetId,
      projectId: this.projectId,
      creatorId: this.creatorId,
      name: this.name,
      description: this.description,
    };
  }
}
