import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/users/user';
import { UserLocalService } from '../../services/local/user-local.service';
import { ViewDidEnter } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, ViewDidEnter {
  @Input() me: User = new User();

  constructor(
    private readonly userLocalService: UserLocalService,
    private readonly router: Router
  ) {}

  async ngOnInit() {}

  async ionViewDidEnter() {
    this.me = this.userLocalService.getMe();
    if (!this.me?.userId) {
      localStorage.removeItem('amt-me');
      this.router.navigate(['/login']);
    }
  }
}
