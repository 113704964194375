export class Address {
  street1: string = '';

  street2?: string = '';

  city: string = '';

  state: string = '';

  country: string = '';

  pinCode: string = '';

  constructor(address?: { [key: string]: any }) {
    if (address) {
      this.street1 = address['street1'] || '';

      this.street2 = address['street2'] || '';

      this.city = address['city'] || '';

      this.state = address['state'] || '';

      this.country = address['country'] || '';

      this.pinCode = address['pinCode'] || '';
    }
  }
}
