import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./app-pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./app-pages/clients/clients.module').then(
        (m) => m.ClientsPageModule
      ),
  },
  {
    path: 'clients/:clientId/projects',
    loadChildren: () =>
      import('./app-pages/projects/projects.module').then(
        (m) => m.ProjectsPageModule
      ),
  },
  {
    path: 'clients/:clientId/projects/:projectId/assets',
    loadChildren: () =>
      import('./app-pages/assets/assets.module').then(
        (m) => m.AssetsPageModule
      ),
  },
  {
    path: 'clients/:clientId/projects/:projectId/assets/:assetId/asset-versions',
    loadChildren: () =>
      import('./app-pages/asset-versions/asset-versions.module').then(
        (m) => m.AssetVersionsPageModule
      ),
  },
  {
    path: 'clients/:clientId/projects/:projectId/assets/:assetId/asset-version-details',
    loadChildren: () =>
      import(
        './app-pages/asset-version-details/asset-version-details.module'
      ).then((m) => m.AssetVersionDetailsPageModule),
  },
  {
    path: 'clients/:clientId/projects/:projectId/assets/:assetId/asset-versions/:assetVersionId/asset-version-details',
    loadChildren: () =>
      import(
        './app-pages/asset-version-details/asset-version-details.module'
      ).then((m) => m.AssetVersionDetailsPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./app-pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'verify-email',
    loadChildren: () =>
      import('./app-pages/verify-email/verify-email.module').then(
        (m) => m.VerifyEmailPageModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./app-pages/profile/profile.module').then(
        (m) => m.ProfilePageModule
      ),
  },
  {
    path: '',
    redirectTo: 'clients',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'clients',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
