import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { VerticalTabsComponent } from './components/vertical-tabs/vertical-tabs.component';
import { EnterKeyDirective } from './directives/enterKey/enter-key.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

import { OffsetUserFormComponent } from './components/offset-user-form/offset-user-form.component';
import { ClientUserFormComponent } from './components/client-user-form/client-user-form.component';

@NgModule({
  declarations: [
    TimeAgoPipe,
    FileSizePipe,
    PaginationComponent,
    HeaderComponent,
    LoaderComponent,
    EnterKeyDirective,
    VerticalTabsComponent,
    OffsetUserFormComponent,
    ClientUserFormComponent,
  ],
  imports: [CommonModule, FormsModule, IonicModule, RouterModule],
  exports: [
    PaginationComponent,
    HeaderComponent,
    TimeAgoPipe,
    FileSizePipe,
    LoaderComponent,
    EnterKeyDirective,
    VerticalTabsComponent,
    OffsetUserFormComponent,
    ClientUserFormComponent,
  ],
})
export class SharedModule {}
