import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appEnterKey]',
})
export class EnterKeyDirective {
  @Output() appEnterKey = new EventEmitter<void>();

  @HostListener('keydown.enter', ['$event'])
  onEnterKeydown(event: Event): void {
    event.preventDefault();
    this.appEnterKey.emit();
  }
}
