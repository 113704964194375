import { CreateOrganizationDTO } from '../../dtos/organizations/cteate-organization-dto/create-organization.dto';
import { UpdateEmailDTO } from '../../dtos/organizations/update-email-dto/update-email.dto';
import { UpdateOrganizationDTO } from '../../dtos/organizations/update-organization-dto/update-organization.dto';
import { UpdatePhoneDTO } from '../../dtos/organizations/update-phone-dto/update-phone.dto';
import { Email } from '../email/email';
import { Logo } from '../logo/logo';
import { Phone } from '../phone/phone';
import { Project } from '../projects/project';
import { User } from '../users/user';
import { Address } from './address/address';

export class Organization {
  organizationId: string = '';

  name: string = '';

  description?: string = '';

  logo?: Logo = new Logo();

  email?: Email = new Email();

  phone: Phone = new Phone();

  projects: Project[] = [];

  businessType?: string = '';

  address?: Address = new Address();

  users: User[] = [];

  type: 'OFFSET_PRINTER' | 'CLIENT' = 'OFFSET_PRINTER';

  wasNew: boolean = true;

  parentOrganizationId?: string = '';

  timestamp?: { createdAt: number; updatedAt: number };

  constructor(organization?: { [key: string]: any }) {
    if (organization) {
      this.organizationId = organization['organizationId'] || '';

      this.name = organization['name'] || '';

      this.description = organization['description'] || '';

      this.businessType = organization['businessType'] || '';

      this.parentOrganizationId = organization['parentOrganizationId'] || '';

      this.type = organization['type'] || '';

      if (organization['logo']) {
        this.logo = new Logo(organization['logo']);
      }

      if (organization['email']) {
        this.email = new Email(organization['email']);
      }

      if (organization['phone']) {
        this.phone = new Phone(organization['phone']);
      }

      if (organization['address']) {
        this.address = new Address(organization['address']);
      }

      if (organization['projects']?.length) {
        for (let project of organization['projects']) {
          this.projects.push(new Project(project));
        }
      }

      if (organization['users']?.length) {
        for (let user of organization['users']) {
          this.users.push(new User(user));
        }
      }

      if (
        String(organization['wasNew']) === 'true' ||
        String(organization['wasNew']) === 'false'
      ) {
        this.wasNew = String(organization['wasNew']) === 'true';
      }

      this.timestamp = organization['timestamp'] || {
        createdAt: null,
        modifiedAt: null,
      };
    }
  }

  toCreateDTO(): CreateOrganizationDTO {
    return {
      name: this.name,
      type: this.type,
      description: this.description,
      email: this.email?.value ? this.email : undefined,
      phone: this.phone,
      parentOrganizationId: this.parentOrganizationId,
      address: this.address,
      businessType: this.businessType,
    };
  }

  toUpdateDTO(): UpdateOrganizationDTO {
    return {
      organizationId: this.organizationId,
      name: this.name,
      description: this.description,
      businessType: this.businessType,
      email: this.email?.value ? this.email : undefined,
      phone: this.phone?.number ? this.phone : undefined,
      address: this.address,
    };
  }

  toUpdateEmailDTO(): UpdateEmailDTO {
    return {
      organizationId: this.organizationId,
      emailValue: this.email.value,
    };
  }

  toUpdatePhoneDTO(): UpdatePhoneDTO {
    return {
      organizationId: this.organizationId,
      phoneNumber: this.phone.number,
      countryCode: this.phone.countryCode,
    };
  }
}
