<ul class="{{ align }}">
  <li
    class="first"
    (click)="changePage(1)"
    [ngClass]="{ disabled: selectedPage === 1 }"
  >
    First
  </li>
  <li
    class="previous"
    (click)="changePage(selectedPage - 1)"
    [ngClass]="{ disabled: selectedPage === 1 }"
  >
    Previous
  </li>
  <li
    class="page-number"
    (click)="changePage(p)"
    [ngClass]="{ selected: p === selectedPage }"
    *ngFor="let p of pages"
  >
    {{ p }}
  </li>
  <li
    class="next"
    (click)="changePage(selectedPage + 1)"
    [ngClass]="{ disabled: selectedPage === lastPage }"
  >
    Next
  </li>
  <li
    class="last"
    (click)="changePage(lastPage)"
    [ngClass]="{ disabled: selectedPage === lastPage }"
  >
    Last
  </li>
</ul>
