export class Phone {
  countryCode: string = '+91';

  number: string = '';

  otp: string = '';

  verified?: boolean = false;

  constructor(phone?: { [key: string]: any }) {
    if (phone) {
      this.countryCode = phone['countryCode'] || '+91';

      this.number = phone['number'] || '';

      this.otp = phone['otp'] || '';

      if (
        String(phone['verified']) === 'true' ||
        String(phone['verified']) === 'false'
      ) {
        this.verified = String(phone['verified']) === 'true';
      }
    }
  }
}
