import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetUsersDTO } from '../../dtos/users/get-users-dto/get-users.dto';
import { Observable, map } from 'rxjs';
import { Users } from '../../models/users/users';
import { RouteSettings } from '../../settings/route-settings';
import { User } from '../../models/users/user';

import { UpdateUserDTO } from '../../dtos/users/update-user-dto/update-user.dto';
import { RegisterUserDTO } from '../../dtos/users/register-user.dto/register-user.dto';
import { LoginUserDTO } from '../../dtos/users/login-user.dto/login-user.dto';
import { VerifyEmailDTO } from '../../dtos/users/verify-email.dto/verify-email.dto';
import { VerifyPhoneDTO } from '../../dtos/users/verify-phone.dto/verify-phone.dto';
import { ResetPasswordDTO } from '../../dtos/users/reset-password.dto/reset-password.dto';
import { UpdatePasswordDTO } from '../../dtos/users/update-password.dto/update-password.dto';
import { UpdateRoleDTO } from '../../dtos/users/update-role.dto/update-role.dto';
import { ResendVerificationEmailDTO } from '../../dtos/users/resend-verification-email.dto/resend-verification-email.dto';
import { UpdateEmailDTO } from '../../dtos/users/update-email.dto/update-email.dto';
import { UpdatePhoneDTO } from '../../dtos/users/update-phone.dto/update-phone.dto';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getUsers(query?: GetUsersDTO): Observable<Users> {
    let httpParams = new HttpParams();
    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        if (!value) {
          return;
        }
        httpParams = httpParams.append(key, value);
      });
    }

    let getUsersURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.GET;

    return this.httpClient.get(getUsersURL, { params: httpParams }).pipe(
      map(
        (response: { [key: string]: any }) =>
          new Users(
            response['data'] || {
              totalCount: 0,
              currentCount: 0,
              users: [],
            }
          )
      )
    );
  }

  registerUser(registerUserDTO: RegisterUserDTO): Observable<User> {
    let registerUserURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.CREATE;

    return this.httpClient
      .post(registerUserURL, registerUserDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  updateUser(updateUserDTO: UpdateUserDTO): Observable<User> {
    let updateUserURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.UPDATE;

    return this.httpClient
      .put(updateUserURL, updateUserDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  deleteUser(userId: string): Observable<null> {
    let deleteUserUrl: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.DELETE;

    return this.httpClient
      .request('delete', deleteUserUrl, {
        body: { userId },
      })
      .pipe(map((response) => null));
  }

  loginUser(loginUserDTO: LoginUserDTO): Observable<User> {
    let loginUserURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.CREATE_SESSION;

    return this.httpClient
      .post(loginUserURL, loginUserDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  deleteSession(userId: string): Observable<null> {
    let deleteUserUrl: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.DELETE_SESSION;

    return this.httpClient
      .request('delete', deleteUserUrl, {
        body: { userId },
      })
      .pipe(map((response) => null));
  }

  resendVerificationEmail(
    resendVerificationEmailDTO: ResendVerificationEmailDTO
  ): Observable<User> {
    let resendVerificationEmailUrl: string =
      RouteSettings.BASE_URL +
      RouteSettings.ENDPOINTS.USERS.RESEND_VERIFICATION_EMAIL;

    return this.httpClient
      .put(resendVerificationEmailUrl, resendVerificationEmailDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  verifyEmail(verifyEmailDTO: VerifyEmailDTO): Observable<User> {
    let verifyEmailURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.VERIFY_EMAIL;

    return this.httpClient
      .put(verifyEmailURL, verifyEmailDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  verifyPhone(verifyPhoneDTO: VerifyPhoneDTO): Observable<User> {
    let verifyPhoneURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.VERIFY_PHONE;

    return this.httpClient
      .put(verifyPhoneURL, verifyPhoneDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  resetPassword(resetPasswordDTO: ResetPasswordDTO): Observable<User> {
    let resetPasswordURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.RESET_PASSWORD;

    return this.httpClient
      .put(resetPasswordURL, resetPasswordDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  updatePassword(updatePasswordDTO: UpdatePasswordDTO): Observable<User> {
    let updatePasswordURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.UPDATE_PASSWORD;

    return this.httpClient
      .put(updatePasswordURL, updatePasswordDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  updateRole(updateRoleDTO: UpdateRoleDTO): Observable<User> {
    let updateRoleURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.UPDATE_ROLE;

    return this.httpClient
      .put(updateRoleURL, updateRoleDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  uploadProfilePic(userId: string, file: File): Observable<User> {
    let uploadUserProfilePicURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.UPLOAD_PROFILE_PIC;

    const formData = new FormData();

    formData.append('userId', userId);

    formData.append('profilePic', file);

    return this.httpClient
      .post(uploadUserProfilePicURL, formData)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  updateEmail(updateEmailDTO: UpdateEmailDTO): Observable<User> {
    let updateEmailURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.UPDATE_EMAIL;

    return this.httpClient
      .put(updateEmailURL, updateEmailDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }

  updatePhone(updatePhoneDTO: UpdatePhoneDTO): Observable<User> {
    let updatePhoneURL: string =
      RouteSettings.BASE_URL + RouteSettings.ENDPOINTS.USERS.UPDATE_PHONE;

    return this.httpClient
      .put(updatePhoneURL, updatePhoneDTO)
      .pipe(
        map((response: { [key: string]: any }) => new User(response['data']))
      );
  }
}
