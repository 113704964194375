import { LoginUserDTO } from '../../dtos/users/login-user.dto/login-user.dto';
import { RegisterUserDTO } from '../../dtos/users/register-user.dto/register-user.dto';
import { ResetPasswordDTO } from '../../dtos/users/reset-password.dto/reset-password.dto';
import { SendVerificationEmailDTO } from '../../dtos/users/send-verification-email.dto/send-verification-email.dto';
import { UpdateEmailDTO } from '../../dtos/users/update-email.dto/update-email.dto';
import { UpdatePasswordDTO } from '../../dtos/users/update-password.dto/update-password.dto';
import { UpdatePhoneDTO } from '../../dtos/users/update-phone.dto/update-phone.dto';
import { UpdateRoleDTO } from '../../dtos/users/update-role.dto/update-role.dto';
import { UpdateUserDTO } from '../../dtos/users/update-user-dto/update-user.dto';
import { VerifyEmailDTO } from '../../dtos/users/verify-email.dto/verify-email.dto';
import { VerifyPhoneDTO } from '../../dtos/users/verify-phone.dto/verify-phone.dto';
import { Email } from '../email/email';
import { Logo } from '../logo/logo';
import { Organization } from '../organizations/organization';
import { Phone } from '../phone/phone';
import { Token } from '../tokens/token';
import { Password } from './password/password';

export class User {
  organizationId: string = '';

  organization: Organization = new Organization();

  type: 'OFFSET_PRINTER' | 'CLIENT' = 'OFFSET_PRINTER';

  parentOrganizationId?: string = '';

  userId: string = '';

  name: string = '';

  bio?: string = '';

  profilePic?: Logo = new Logo();

  email: Email = new Email();

  phone: Phone = new Phone();

  gender?: 'MALE' | 'FEMALE' | 'OTHERS' = 'MALE';

  password: Password = new Password();

  wasNew: boolean = true;

  role:
    | 'SUPER_ROOT'
    | 'ROOT'
    | 'OP_REVIEWER'
    | 'OP_APPROVER'
    | 'OP_REVIEWER_AND_APPROVER'
    | 'CLIENT_REVIEWER'
    | 'CLIENT_APPROVER'
    | 'CLIENT_REVIEWER_AND_APPROVER' = 'OP_REVIEWER';

  tokens?: Token[] = [];

  timestamp?: { createdAt: number; updatedAt: number };

  constructor(user?: { [key: string]: any }) {
    if (user) {
      if (typeof user['organizationId'] === 'object') {
        this.organization = new Organization(user['organizationId']);
        this.organizationId = this.organization.organizationId;
      } else {
        this.organizationId = user['organizationId'] || '';
      }

      if (typeof user['organization'] === 'object') {
        this.organization = new Organization(user['organization']);
        this.organizationId = this.organization.organizationId;
      }

      this.type = user['type'] || '';

      if (typeof user['parentOrganizationId'] === 'object') {
        this.organization = new Organization(user['parentOrganizationId']);
        this.parentOrganizationId = this.organization.parentOrganizationId;
      } else {
        this.parentOrganizationId = user['parentOrganizationId'] || '';
      }

      if (typeof user['organization'] === 'object') {
        this.organization = new Organization(user['organization']);
        this.parentOrganizationId = this.organization.parentOrganizationId;
      }

      this.userId = user['userId'] || '';

      this.name = user['name'] || '';

      this.bio = user['bio'] || '';

      if (user['profilePic']) {
        this.profilePic = new Logo(user['profilePic']);
      }

      if (user['email']) {
        this.email = new Email(user['email']);
      }

      if (user['phone']) {
        this.phone = new Phone(user['phone']);
      }

      this.gender = user['gender'] || 'MALE';

      if (user['password']) {
        this.password = new Password(user['password']);
      }

      if (
        String(user['wasNew']) === 'true' ||
        String(user['wasNew']) === 'false'
      ) {
        this.wasNew = String(user['wasNew']) === 'true';
      }

      this.role = user['role'] || '';

      this.tokens = [];
      if (user['tokens']) {
        for (let token of user['tokens']) {
          this.tokens.push(new Token(token));
        }
      }

      this.timestamp = user['timestamp'] || {
        createdAt: null,
        modifiedAt: null,
      };
    }
  }

  toLoginDTO(): LoginUserDTO {
    return {
      emailValue: this.email.value,
      password: this.password.plain,
    };
  }

  toRegisterDTO(): RegisterUserDTO {
    return {
      organizationId: this.organizationId,
      type: this.type,
      parentOrganizationId: this.parentOrganizationId,
      name: this.name,
      bio: this.bio,
      email: this.email,
      phone: this.phone,
      gender: this.gender,
      password: this.password.plain,
      role: this.role,
    };
  }

  toUpdateDTO(): UpdateUserDTO {
    return {
      userId: this.userId,
      organizationId: this.organizationId,
      name: this.name,
      bio: this.bio,
      gender: this.gender,
    };
  }

  toResetPasswordDTO(): ResetPasswordDTO {
    return {
      emailValue: this.email.value,
    };
  }

  toSendVerificationEmailDTO(): SendVerificationEmailDTO {
    return {
      emailValue: this.email.value,
    };
  }

  toUpdatePasswordDTO(): UpdatePasswordDTO {
    return {
      emailValue: this.email.value,
      newPassword: this.password.plain,
      currentPassword: this.password.plain,
      passwordOTP: this.password.otp,
    };
  }

  toUpdateRoleDTO(): UpdateRoleDTO {
    return {
      userId: this.userId,
      role: this.role,
    };
  }

  toVerifyEmailDTO(): VerifyEmailDTO {
    return {
      emailValue: this.email.value,
      emailOTP: this.email.otp,
    };
  }

  toVerifyPhoneDTO(): VerifyPhoneDTO {
    return {
      phoneNumber: this.phone.number,
      phoneOTP: this.phone.otp,
    };
  }

  toUpdateEmailDTO(): UpdateEmailDTO {
    return {
      userId: this.userId,
      emailValue: this.email.value,
    };
  }

  toUpdatePhoneDTO(): UpdatePhoneDTO {
    return {
      userId: this.userId,
      phoneNumber: this.phone.number,
      countryCode: this.phone.countryCode,
    };
  }
}
