import { CreateProjectDTO } from '../../dtos/projects/create-project-dto/create-project.dto';
import { UpdateProjectDTO } from '../../dtos/projects/update-project-dto/update-project.dto';
import { OrganizationTypeEnum } from '../../settings/app-settings';
import { Asset } from '../assets/asset';
import { Organization } from '../organizations/organization';

export class Project {
  offsetPrinterId: string = '';

  offsetPrinterOrganization: Organization = new Organization();

  creatorId: string = '';

  clientOrganization: Organization = new Organization();

  projectId: string = '';

  clientId: string = '';

  name: string = '';

  description?: string = '';

  ownership: keyof typeof OrganizationTypeEnum;

  deleted: boolean = false;

  deadline?: number = 0;

  assets?: Asset[] = [];

  timestamp?: { createdAt: number; updatedAt: number };

  constructor(project?: { [key: string]: any }) {
    if (project) {
      if (typeof project['offsetPrinterId'] === 'object') {
        this.offsetPrinterOrganization = new Organization(
          project['offsetPrinterId']
        );
        this.offsetPrinterId = this.offsetPrinterOrganization.organizationId;
      } else {
        this.offsetPrinterId = project['offsetPrinterId'];
      }

      if (project['offsetPrinterOrganization']) {
        this.offsetPrinterOrganization = new Organization(
          project['offsetPrinterOrganization']
        );
        this.offsetPrinterId = this.offsetPrinterOrganization.organizationId;
      }

      this.creatorId = project['creatorId'] || '';

      this.projectId = project['projectId'] || '';

      if (
        typeof project['clientId'] === 'object' ||
        project['clientOrganization']
      ) {
        this.clientOrganization = new Organization(project['clientId']);
        this.clientId = this.clientOrganization.organizationId;
      } else {
        this.clientId = project['clientId'];
      }

      this.name = project['name'] || '';

      this.ownership = project['ownership'] || '';

      this.description = project['description'] || '';

      if (
        String(project['deleted']) === 'true' ||
        String(project['deleted']) === 'false'
      ) {
        this.deleted = String(project['deleted']) === 'true';
      }

      if (!isNaN((project['deadline'] = parseInt(project['deadline'])))) {
        this.deadline = project['deadline'];
      }

      if (project['assets']) {
        for (let asset of project['assets']) {
          this.assets.push(new Asset(asset));
        }
      }

      this.timestamp = project['timestamp'] || {
        createdAt: null,
        modifiedAt: null,
      };
    }
  }

  toCreateDTO(): CreateProjectDTO {
    return {
      offsetPrinterId: this.offsetPrinterId,
      creatorId: this.creatorId,
      clientId: this.clientId,
      name: this.name,
      description: this.description ? this.description : undefined,
      ownership: this.ownership,
      deadline: this.deadline ? this.deadline : undefined,
    };
  }

  toUpdateDTO(): UpdateProjectDTO {
    return {
      projectId: this.projectId,
      clientId: this.clientId,
      name: this.name,
      description: this.description,
      deadline: this.deadline,
    };
  }
}
