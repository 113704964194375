import { SupportingFile } from '../supporting-file/supporting-file';

export class SupportingFiles {
  layout?: SupportingFile = new SupportingFile();

  printing?: SupportingFile = new SupportingFile();

  metallicWhiteSeparation?: SupportingFile = new SupportingFile();

  UVDripOff?: SupportingFile = new SupportingFile();

  UVVarnish?: SupportingFile = new SupportingFile();

  aquaVarnish?: SupportingFile = new SupportingFile();

  emboss?: SupportingFile = new SupportingFile();

  spotUV?: SupportingFile = new SupportingFile();

  hotFoil?: SupportingFile = new SupportingFile();

  dieline?: SupportingFile = new SupportingFile();

  constructor(supportingFiles?: { [key: string]: any }) {
    if (supportingFiles) {
      if (supportingFiles['layout']) {
        this.layout = new SupportingFile(supportingFiles['layout']);
      }

      if (supportingFiles['printing']) {
        this.printing = new SupportingFile(supportingFiles['printing']);
      }

      if (supportingFiles['metallicWhiteSeparation']) {
        this.metallicWhiteSeparation = new SupportingFile(
          supportingFiles['metallicWhiteSeparation']
        );
      }

      if (supportingFiles['UVDripOff']) {
        this.UVDripOff = new SupportingFile(supportingFiles['UVDripOff']);
      }

      if (supportingFiles['UVVarnish']) {
        this.UVVarnish = new SupportingFile(supportingFiles['UVVarnish']);
      }

      if (supportingFiles['aquaVarnish']) {
        this.aquaVarnish = new SupportingFile(supportingFiles['aquaVarnish']);
      }

      if (supportingFiles['emboss']) {
        this.emboss = new SupportingFile(supportingFiles['emboss']);
      }

      if (supportingFiles['spotUV']) {
        this.spotUV = new SupportingFile(supportingFiles['spotUV']);
      }

      if (supportingFiles['hotFoil']) {
        this.hotFoil = new SupportingFile(supportingFiles['hotFoil']);
      }

      if (supportingFiles['dieline']) {
        this.dieline = new SupportingFile(supportingFiles['dieline']);
      }
    }
  }
}
