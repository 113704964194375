import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { Organization } from 'src/app/shared/models/organizations/organization';
import { User } from 'src/app/shared/models/users/user';
import { UsersApiService } from 'src/app/shared/services/api/users-api.service';
import { ToasterService } from 'src/app/shared/services/helpers/toaster.service';
import {
  UserGenderEnum,
  UserRoleEnum,
} from 'src/app/shared/settings/app-settings';

@Component({
  selector: 'app-client-user-form',
  templateUrl: './client-user-form.component.html',
  styleUrls: ['./client-user-form.component.scss'],
})
export class ClientUserFormComponent implements OnInit {
  @ViewChild('clientUserForm', { static: false }) clientUserForm: any;
  @Input() me: User = new User();
  @Input() user: User = new User();
  @Input() organizations: Organization[] = [];
  @Input() client: Organization = new Organization();

  type: 'register' | 'edit' = 'register';

  isDisabled: boolean = false;
  gender = UserGenderEnum;
  roles: any = {};

  constructor(
    public readonly modalController: ModalController,
    public readonly toaster: ToasterService,
    private toastr: ToastrService,
    private readonly usersAPIService: UsersApiService
  ) {}

  ngOnInit() {
    this.type = 'register';

    if (this.user.userId) {
      this.type = 'edit';

      this.isDisabled = true;
    }

    for (let role of Object.keys(UserRoleEnum)) {
      if (role.startsWith('CLIENT')) {
        this.roles[role] = UserRoleEnum[role as keyof typeof UserRoleEnum];
      }
    }
  }

  async save() {
    if (this.clientUserForm.invalid) {
      for (let key in this.clientUserForm.controls) {
        this.clientUserForm.controls[key].markAsDirty();
        this.clientUserForm.controls[key].markAsTouched();
      }
      this.toastr.error('Please fill the required values');

      return;
    }

    if (this.me && this.me.organizationId) {
      this.user.parentOrganizationId = this.me.organizationId;
      this.user.type = 'CLIENT';
    } else {
      console.error(
        'Invalid parent organization ID',
        this.user.parentOrganizationId
      );
    }

    if (this.type === 'register') {
      try {
        this.user = await lastValueFrom(
          this.usersAPIService.registerUser(this.user.toRegisterDTO())
        );
      } catch (e: any) {
        this.toastr.error(
          e && e.error && e.error.status === 'ERROR'
            ? e.error.message.user
            : 'An unknown error occurred saving user'
        );
      }
    } else {
      try {
        await lastValueFrom(
          this.usersAPIService.updateUser(this.user.toUpdateDTO())
        );
        await lastValueFrom(
          this.usersAPIService.updateRole(this.user.toUpdateRoleDTO())
        );

        await lastValueFrom(
          this.usersAPIService.updateEmail(this.user.toUpdateEmailDTO())
        );

        await lastValueFrom(
          this.usersAPIService.updatePhone(this.user.toUpdatePhoneDTO())
        );
      } catch (e) {}
    }

    this.toastr.success(
      'Client successfully ' + (this.type === 'register' ? 'added' : 'updated')
    );

    this.modalController.dismiss(this.user);
  }
}
