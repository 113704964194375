export class Logo {
  _id: string = '';

  url: string = '';

  size?: number = 0;

  type?: string = '';

  fileType?: string = '';

  fileName?: string = '';

  timestamp?: { createdAt: number; modifiedAt: number };

  constructor(logo?: { [key: string]: any }) {
    if (logo) {
      this._id = logo['_id'] || '';

      this.url = logo['url'] || '';

      this.type = logo['type'] || '';

      if (!isNaN((logo['size'] = parseInt(logo['size'])))) {
        this.size = logo['size'];
      }

      const urlSplit = this.url.split('.');
      this.fileType = urlSplit[urlSplit.length - 1].toUpperCase();

      const nameSplit = this.url.split('/');
      this.fileName = nameSplit[nameSplit.length - 1].toUpperCase();

      this.timestamp = logo['timestamp'] || {
        createdAt: null,
        modifiedAt: null,
      };
    }
  }
}
