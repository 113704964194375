import { environment } from 'src/environments/environment';

export class RouteSettings {
  public static readonly BASE_URL = environment.BACKEND_BASE_URL;

  public static readonly ENDPOINTS = {
    ORGANIZATIONS: {
      GET: '/organizations',
      CREATE_OFFSET_PRINTER: '/organizations/offset-printers',
      CREATE_CLIENT: '/organizations/clients',
      UPDATE: '/organizations',
      DELETE: '/organizations',
      UPLOAD_LOGO: '/organizations/logo',
      UPDATE_EMAIL: '/organizations/email/update',
      UPDATE_PHONE: '/organizations/phone/update',
    },

    USERS: {
      GET: '/users',
      CREATE: '/users',
      UPDATE: '/users',
      DELETE: '/users',
      CREATE_SESSION: '/users/session',
      DELETE_SESSION: '/users/session',
      RESEND_VERIFICATION_EMAIL: '/users/email/verify',
      VERIFY_EMAIL: '/users/email/verified',
      VERIFY_PHONE: '/users/phone/verified',
      RESET_PASSWORD: '/users/password/reset',
      UPDATE_PASSWORD: '/users/password/hash',
      UPDATE_ROLE: '/users/role',
      UPLOAD_PROFILE_PIC: '/users/profile-pic',
      UPDATE_EMAIL: '/users/email/update',
      UPDATE_PHONE: '/users/phone/update',
    },

    TOKENS: {
      GET: '/tokens',
      CREATE: '/tokens',
      UPDATE: '/tokens',
      DELETE: '/tokens',
    },

    PROJECTS: {
      GET: '/projects',
      CREATE: '/projects',
      UPDATE: '/projects',
      DELETE: '/projects',
      DELETE_PERMANENT: '/projects/permanent',
      UPDATE_RESTORE: '/projects/restore',
    },

    ASSETS: {
      GET: '/assets',
      CREATE: '/assets',
      UPDATE: '/assets',
      DELETE: '/assets',
      DELETE_PERMANENT: '/assets/permanent',
      UPDATE_RESTORE: '/assets/restore',
      UPLOAD_SUPPORTING_FILES: '/assets/supporting-files',
      DELETE_SUPPORTING_FILES: '/assets/supporting-files',
    },

    ASSETVERSIONS: {
      GET: '/asset-versions',
      CREATE: '/asset-versions',
      UPDATE: '/asset-versions',
      DELETE: '/asset-versions',
      DELETE_PERMANENT: '/asset-versions/permanent',
      UPDATE_RESTORE: '/asset-versions/restore',
      UPLOAD_FILE: '/asset-versions/file',
      UPDATE_STATUS: '/asset-versions/status',
      UPLOAD_PROOF_FILE: '/asset-versions/proof-file',
      DELETE_PROOF_FILE: '/asset-versions/proof-file',
    },

    COMMENTS: {
      GET: '/comments',
      CREATE: '/comments',
      UPDATE: '/comments',
      DELETE: '/comments',
      DELETE_PERMANENT: '/comments/permanent',
      UPDATE_RESTORE: 'comments/restore',
    },
  };
}
