<ion-header class="ion-no-border">
  <ion-toolbar>
    <div class="header">
      <ul>
        <li routerLink="/clients" routerDirection="root">
          <img
            src="https://packit-innovations.s3.eu-north-1.amazonaws.com/assets/logo.png"
          />

          <p class="logo-name">Version Guru</p>
        </li>
        <li routerLink="/profile" routerDirection="root">
          <div class="profile-info">
            <p class="sub2 cp">{{ me.name }}</p>
            <p class="caption">{{ me.email.value }}</p>
          </div>
          <div class="profile-pic">
            <img src="{{ me.profilePic.url || '/assets/images/user.png' }}" />
          </div>
        </li>
      </ul>
    </div>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>
