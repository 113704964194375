export class AppSettings {
  public static readonly ITEMS_PER_PAGE = 10;

  // public static readonly SETTING_1 = 'setting_value';
  public static readonly ROLES = {
    SUPER_ROOT: {
      role: 'SUPER_ROOT',
      description: 'Super root users',
      childRoles: [
        'SUPER_ROOT',
        'ROOT',
        'OP_REVIEWER',
        'OP_APPROVER',
        'OP_REVIEWER_AND_APPROVER',
        'CLIENT_REVIEWER',
        'CLIENT_APPROVER',
        'CLIENT_REVIEWER_AND_APPROVER',
      ],
      visible: true,
    },
    ROOT: {
      role: 'ROOT',
      description: 'ROOT users',
      childRoles: [
        'ROOT',
        'OP_REVIEWER',
        'OP_APPROVER',
        'OP_REVIEWER_AND_APPROVER',
        'CLIENT_REVIEWER',
        'CLIENT_APPROVER',
        'CLIENT_REVIEWER_AND_APPROVER',
      ],
      visible: true,
    },

    OP_REVIEWER: {
      role: 'OP_REVIEWER',
      description: 'OP_REVIEWER users',
      childRoles: ['OP_REVIEWER'],
      visible: true,
    },

    OP_APPROVER: {
      role: 'OP_APPROVER',
      description: 'OP_APPROVER users',
      childRoles: ['OP_APPROVER'],
      visible: true,
    },

    OP_REVIEWER_AND_APPROVER: {
      role: 'OP_REVIEWER_AND_APPROVER',
      description: 'OP_REVIEWER_AND_APPROVER users',
      childRoles: ['OP_REVIEWER_AND_APPROVER', 'OP_REVIEWER', 'OP_APPROVER'],
      visible: true,
    },

    CLIENT_REVIEWER: {
      role: 'CLIENT_REVIEWER',
      description: 'CLIENT_REVIEWER users',
      childRoles: ['CLIENT_REVIEWER'],
      visible: true,
    },

    CLIENT_APPROVER: {
      role: 'CLIENT_APPROVER',
      description: 'CLIENT_APPROVER users',
      childRoles: ['CLIENT_APPROVER'],
      visible: true,
    },

    CLIENT_REVIEWER_AND_APPROVER: {
      role: 'CLIENT_REVIEWER_AND_APPROVER',
      description: 'CLIENT_REVIEWER_AND_APPROVER users',
      childRoles: [
        'CLIENT_REVIEWER_AND_APPROVER',
        'CLIENT_REVIEWER',
        'CLIENT_APPROVER',
      ],
      visible: true,
    },
  };

  public static readonly MIME_TYPES: string[] = [
    'image/jpg',
    'image/jpeg',
    'image/svg',
    'application/svg',
    'image/svg+xml',
    'application/coreldraw',
    'application/x-cdr',
    'application/x-coreldraw',
    'image/cdr',
    'image/x-cdr',
    'zz-application/zz-winassoc-cdr',
    'application/cdr',
    'application/pdf',
    'application/zip',
    'application/epub+zip',
    'image/gif',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/rtf',
    'application/vnd.rar',
    'image/svg+xml',
    'font/ttf',
    'font/woff',
    'font/woff2',
    'application/x-7z-compressed',
    'application/octet-stream',
    'image/svg+xml;charset=utf-8',
    'image/svg+xml;utf-8',
    'image/webp',
  ];

  public static readonly ASSET_VERSION_LOGOS: { [key: string]: any } = {
    'image/jpg': '/assets/images/image-logo.svg',
    'image/webp': '/assets/images/webp-icon.svg',
    'application/svg': '/assets/images/svg-icon.svg',
    'image/svg+xml': '/assets/images/svg-icon.svg',
    'image/svg+xml;charset=utf-8': '/assets/images/svg-icon.svg',
    'image/svg+xml;utf-8': '/assets/images/svg-icon.svg',
    'image/jpeg': '/assets/images/image-logo.svg',
    'image/png': '/assets/images/image-logo.svg',
    'application/coreldraw': '/assets/images/cdr-logo.svg',
    'application/x-cdr': '/assets/images/cdr-logo.svg',
    'application/octet-stream': '/assets/images/cdr-logo.svg',
    'application/x-coreldraw': '/assets/images/cdr-logo.svg',
    'image/cdr': '/assets/images/cdr-logo.svg',
    'image/x-cdr': '/assets/images/cdr-logo.svg',
    'zz-application/zz-winassoc-cdr': '/assets/images/cdr-logo.svg',
    'application/cdr': '/assets/images/cdr-logo.svg',
    'application/pdf': '/assets/images/pdf-logo.svg',
    'application/zip': '/assets/images/docs-logo.svg',
    'application/epub+zip': '/assets/images/docs-logo.svg',
    'image/gif': '/assets/images/image-logo.svg',
    'application/vnd.ms-powerpoint': '/assets/images/docs-logo.svg',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      '/assets/images/docs-logo.svg',
    'application/rtf': '/assets/images/docs-logo.svg',
    'application/vnd.rar': '/assets/images/docs-logo.svg',
    // 'image/svg+xml': '/assets/images/docs-logo.svg',
    'font/ttf': '/assets/images/docs-logo.svg',
    'font/woff': '/assets/images/docs-logo.svg',
    'font/woff2': '/assets/images/docs-logo.svg',
    'application/x-7z-compressed': '/assets/images/docs-logo.svg',
  };

  public static readonly ASSET_VERSION_STATUS_FLOWS: { [key: string]: any } = {
    IN_REVIEW: {
      IN_REVIEW: {
        key: 'IN_REVIEW',
        value: 'In Review',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
      APPROVED: { key: 'APPROVED', value: 'Approved', roles: ['CLIENT'] },
      REJECTED: {
        key: 'REJECTED',
        value: 'Rejected',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
      VERIFIED: {
        key: 'VERIFIED',
        value: 'Verified',
        roles: ['OFFSET_PRINTER'],
      },
      FILES_OK: {
        key: 'FILES_OK',
        value: 'Files OK',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
    },

    VERIFIED: {
      VERIFIED: {
        key: 'VERIFIED',
        value: 'Verified',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
    },

    APPROVED: {
      APPROVED: {
        key: 'APPROVED',
        value: 'Approved',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
      INACTIVE: {
        key: 'INACTIVE',
        value: 'Inactive',
        roles: ['OFFSET_PRINTER'],
      },
    },

    REJECTED: {
      REJECTED: {
        key: 'REJECTED',
        value: 'Rejected',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
    },

    INACTIVE: {
      INACTIVE: {
        key: 'INACTIVE',
        value: 'Inactive',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
    },

    FILES_OK: {
      FILES_OK: {
        key: 'FILES_OK',
        value: 'Files OK',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
      APPROVED: {
        key: 'APPROVED',
        value: 'Approved',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
      REJECTED: {
        key: 'REJECTED',
        value: 'Rejected',
        roles: ['OFFSET_PRINTER', 'CLIENT'],
      },
    },
  };
}

export enum OrganizationTypeEnum {
  OFFSET_PRINTER = 'Offset Printer',
  CLIENT = 'Client',
}

export enum UserGenderEnum {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHERS = 'Others',
}

export enum UserRoleEnum {
  SUPER_ROOT = 'Super Root',
  ROOT = 'Root',
  OP_REVIEWER = 'OP Reviewer',
  OP_APPROVER = 'OP Approver',
  OP_REVIEWER_AND_APPROVER = 'OP Reviewer and Approver',
  CLIENT_REVIEWER = 'Client Reviewer',
  CLIENT_APPROVER = 'Client Approver',
  CLIENT_REVIEWER_AND_APPROVER = 'Client Reviewer and Approver',
}

export enum PermissionRestrictionEnum {
  organizationId = 'organizationId',
  userId = 'userId',
  '' = '',
}

export enum AssetTypeEnum {
  ARTWORK = 'ARTWORK',
}

export enum AssetVersionStatusEnum {
  IN_REVIEW = 'In Review',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  INACTIVE = 'Inactive',
  VERIFIED = 'Verified',
  FILES_OK = 'Files OK',
  UNASSIGNED = 'Unassigned',
}

export enum AssetVersionDetailTagEnum {
  COLOR = 'Color',
  KEYLINE = 'Keyline',
  TEXT = 'Text',
  IMAGE = 'Image',
  SURFACE_FINISH = 'Surface finish',
  BARCODE = 'Barcode',
  AI_FILE_VERSION = 'AI file version',
  CDR_FILE_VERSION = 'CDR file version',
  COMPLIANCE = 'Compliance',
}

export enum AssetSupportingFileTypeEnum {
  layout = 'Layout',
  printing = 'Printing',
  metallicWhiteSeparation = 'Metallic White Separation',
  UVDripOff = 'UV Drip Off',
  UVVarnish = 'UV Varnish',
  aquaVarnish = 'Aqua Varnish',
  emboss = 'Emboss',
  spotUV = 'Spot UV',
  hotFoil = 'Hot Foil',
  dieline = 'Dieline',
}

export type AssetVersionStatus = keyof typeof AssetVersionStatusEnum;

export type AssetVersionDetailTag = keyof typeof AssetVersionDetailTagEnum;

export type OrganizationType = keyof typeof OrganizationTypeEnum;
