import { CreateAssetVersionDTO } from '../../dtos/asset-versions/create-asset-version-dto/create-asset-version.dto';
import { UpdateAssetVersionDTO } from '../../dtos/asset-versions/update-asset-version-dto/update-asset-version.dto';
import { UpdateStatusDTO } from '../../dtos/asset-versions/update-status-dto/update-status.dto';
import { AssetVersionStatus } from '../../settings/app-settings';
import { Asset } from '../assets/asset';
import { Logo } from '../logo/logo';
import { Organization } from '../organizations/organization';
import { Project } from '../projects/project';

export class AssetVersion {
  offsetPrinterId: string = '';

  projectId: string = '';

  project: Project = new Project();

  clientId: string = '';

  clientOrganization: Organization = new Organization();

  assetId: string = '';

  asset: Asset = new Asset();

  assetVersionId: string = '';

  creatorId: string = '';

  prefix: string = '';

  name: string = '';

  suffix: string = '';

  description?: string = '';

  file: Logo = new Logo();

  proofFile: Logo = new Logo();

  status: AssetVersionStatus = 'IN_REVIEW';

  deleted: boolean = false;

  idx: number = 0;

  reason?: string = '';

  timestamp?: { createdAt: number; updatedAt: number };

  constructor(assetVersion?: { [key: string]: any }) {
    if (assetVersion) {
      this.offsetPrinterId = assetVersion['offsetPrinterId'] || '';

      this.projectId = assetVersion['projectId'] || '';

      if (typeof assetVersion['projectId'] === 'object') {
        this.project = new Project(assetVersion['projectId']);
        this.projectId = this.project.projectId;
      } else {
        this.projectId = assetVersion['projectId'] || '';
      }

      if (typeof assetVersion['project'] === 'object') {
        this.project = new Project(assetVersion['project']);
        this.projectId = this.project.projectId;
      }

      if (typeof assetVersion['clientId'] === 'object') {
        this.clientOrganization = new Organization(assetVersion['clientId']);
        this.clientId = this.clientOrganization.organizationId;
      } else {
        this.clientId = assetVersion['clientId'] || '';
      }

      if (typeof assetVersion['clientOrganization'] === 'object') {
        this.clientOrganization = new Organization(
          assetVersion['clientOrganization']
        );
        this.clientId = this.clientOrganization.organizationId;
      }

      if (typeof assetVersion['assetId'] === 'object') {
        this.asset = new Asset(assetVersion['assetId']);
        this.assetId = this.asset.assetId;
      } else {
        this.assetId = assetVersion['assetId'] || '';
      }

      if (typeof assetVersion['asset'] === 'object') {
        this.asset = new Asset(assetVersion['asset']);
        this.assetId = this.asset.assetId;
      }

      this.assetVersionId = assetVersion['assetVersionId'] || '';

      this.creatorId = assetVersion['creatorId'] || '';

      this.name = assetVersion['name'] || '';

      this.description = assetVersion['description'] || '';

      if (assetVersion['file']) {
        this.file = new Logo(assetVersion['file']);
      }

      this.proofFile = null;
      if (assetVersion['proofFile']) {
        this.proofFile = new Logo(assetVersion['proofFile']);
      }

      this.status = assetVersion['status'] || 'IN_REVIEW';

      if (
        String(assetVersion['deleted']) === 'true' ||
        String(assetVersion['deleted']) === 'false'
      ) {
        this.deleted = String(assetVersion['deleted']) === 'true';
      }

      this.reason = assetVersion['reason'] || '';

      if (!isNaN((assetVersion['idx'] = parseInt(assetVersion['idx'])))) {
        this.idx = assetVersion['idx'];
      }

      this.timestamp = assetVersion['timestamp'] || {
        createdAt: null,
        modifiedAt: null,
      };
    }
  }

  toCreateDTO(): CreateAssetVersionDTO {
    return {
      offsetPrinterId: this.offsetPrinterId,
      projectId: this.projectId,
      clientId: this.clientId,
      assetId: this.assetId,
      creatorId: this.creatorId,
      // name: this.prefix + this.name + this.suffix,
      name: this.prefix + this.suffix,

      description: this.description,
      status: this.status,
      deleted: this.deleted,
    };
  }

  toUpdateDTO(): UpdateAssetVersionDTO {
    return {
      assetVersionId: this.assetVersionId,
      projectId: this.projectId,
      assetId: this.assetId,
      creatorId: this.creatorId,
      // name: this.prefix + this.name + this.suffix,
      name: this.prefix + this.suffix,

      description: this.description,
    };
  }

  toUpdateStatusDTO(): UpdateStatusDTO {
    return {
      assetVersionId: this.assetVersionId,
      projectId: this.projectId,
      assetId: this.assetId,
      creatorId: this.creatorId,
      status: this.status,
      reason: this.reason,
    };
  }
}
