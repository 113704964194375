export class SupportingFile {
  _id?: string;

  url?: string;

  size?: number;

  type?: string;

  assetVersionId: string;

  fileType?: string;

  file?: File;

  constructor(supportingFile?: { [key: string]: any }) {
    if (supportingFile) {
      this._id = supportingFile['_id'] || '';

      this.url = supportingFile['url'] || '';

      this.type = supportingFile['type'] || '';

      if (!isNaN((supportingFile['size'] = parseInt(supportingFile['size'])))) {
        this.size = supportingFile['size'];
      }

      this.assetVersionId = supportingFile['assetVersionId'] || '';

      this.fileType = supportingFile['fileType'] || '';

      this.file = supportingFile['file'] || null;
    }
  }
}
